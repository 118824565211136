@import "../../styles/colors.scss";

a {
    cursor: default;
    .header {
        display: flex;
        height: 55px;
        justify-content: center;
        align-items: center;
        img {
            width:50%;
            max-width: 500px;
            height: auto;
            &:hover {
                cursor: pointer;
            }
        }
    }
}

a {
    cursor: default;
    .footer {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 20px;
        border-bottom: 1px solid rgba(151, 151, 151, 0.22);
        img {
            width: 100%;
            max-width: 200px;
            &:hover {
                cursor: pointer;
            }
        }
    }
}