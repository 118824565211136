@import "../../styles/colors.scss";

.searchContainer {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  .searchIcon {
    color: $blue-light;
  }
  .searchInput {
    margin-left: 10px;
    color: $blue-dark;
  }
  .inputExpanded {
    width: 200px;
    color: $blue-dark;
  }
}

.resultContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  justify-content: center;
  align-items: center;
  &__resultCard {
    width: 320px;
    height: auto;
    border-radius: 8px;
    border: 1px solid rgba(236, 236, 236, 0.8);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px 0px;
    &__Img {
      width: 100%;
      height: 250px;
      &:hover {
        cursor: pointer;
        filter: opacity(0.9);
      }
    }
    &__Brand {
      font-size: 10px;
    }
    &__Url {
      color: $gray-medium;
      &:hover {
        cursor: pointer;
        color: $blue-light;
      }
      &:visited {
        color: inherit;
      }
      > div {
        > p {
          color: $gray-primary;
        }
      }
    }
    &__ContentDiv {
      width: 90%;
      margin-left: 15px;
      > h2 {
        font-weight: 500;
        font-size: 22px;
        line-height: 42px;
      }
      > p {
        text-align: justify;
        font-size: 12px;
        height: 70px;
        overflow: "hidden";
      }
      &__UrlDiv {
        > p {
          padding: 10px 0;
          font-size: 15px;
          display: flex;
          align-items: center;
          gap: 10px;
          text-decoration: none;
          color: $gray-medium;
          &:hover {
            cursor: pointer;
            color: $blue-light;
          }
          &:visited {
            color: inherit;
          }
        }
      }
    }
  }
}
