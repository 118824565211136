@import "../../styles/colors.scss";

.ProductContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
  width: 100%;
  > h3 {
    font-size: 22px;
    line-height: 42px;
    margin-top: 20px;
  }
  &__Header {
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: center;
    gap: 0 30px;
    > h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 54px;
    }
  }
  &__GaleriaDescripcion {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 90%;
    flex-wrap: wrap;
    gap: 15px;
    &__Izq {
      width: 800px;
      > p {
        font-size: 1rem;
      }
    }
    &__Der {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 550px;
      padding: 0 10px;
      > h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 42px;
      }
      &__Card {
        padding: 1rem;
        background-color: #fff;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
          0 4px 6px -2px rgba(0, 0, 0, 0.05);
        max-width: 320px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        gap: 10px;
        margin-bottom: 15px;
        &__Components {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 110px;
          height: 110px;
          border: none;
          font-size: 1.5rem;
          color: #fff;
          border-radius: 50%;
          letter-spacing: 4px;
          font-weight: 700;
          text-transform: uppercase;
          transition: 0.5s;
          transition-property: box-shadow;
          background: linear-gradient(145deg, #008ce6, #323f52);
          box-shadow: 0 0 15px rgb(0, 140, 255);
        }
      }
    }
  }
  .ProductCaracteristicas {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    > h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 42px;
    }
    > p {
      justify-content: flex-start;
      line-height: 30px;
    }
  }
  .ProductsRelacionados {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
    &__Card2 {
      padding: 1rem;
      background-color: #fff;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
      max-width: 320px;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      gap: 10px;
      margin-bottom: 40px;
      &__Img {
        width: 100%;
        position: relative;
        > img {
          width: 100%;
        }
        > button {
          display: flex;
          align-items: center;
          position: absolute;
          justify-content: space-around;
          width: 90%;
          bottom: -30px;
          right: 0;
          left: 0;
          margin: auto;
          padding: 10px;
          border: none;
          font-size: 13px;
          color: #fff;
          border-radius: 7px;
          letter-spacing: 4px;
          font-weight: 700;
          text-transform: uppercase;
          transition: 0.5s;
          transition-property: box-shadow;
          background: linear-gradient(145deg, #323f52, #008ce6);
          box-shadow: 0 0 15px rgb(0, 140, 255);
          cursor: pointer;
          &:hover {
            box-shadow: 0 0 5px rgb(0, 140, 255), 0 0 5px rgb(0, 140, 255),
              0 0 10px rgb(0, 140, 255), 0 0 10px rgb(0, 140, 255);
          }
        }
      }
    }
  }
}
