.youtubeSliderContainer {
  background: var(--surface-card);
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  width: 97%;
}

.frameContainer {
  display: flex;
  gap: 10px;
  align-items: baseline;
  padding: 0.2rem;
  border-radius: 10px;
}
