.Otros {
  &__Header {
    height: 90px;
    width: 100%;
    border-radius: 0px 0px 40px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    &__DivImg {
      width: 100%;
      display: flex;
    }
  }
  &__Bread {
    margin-top: 20px;
    margin-bottom: 10px;
    padding-left: 20px;
  }
}
