@import "../../styles/colors.scss";

.Home {
  width: 100%;
  position: relative;
  z-index: 1;
  > h2 {
    text-align: center;
  }
  &__ServiceContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    height: 450px;
    gap: 10px;
    background-color: $gray-light;
    &__Slider {
      align-items: center;
      width: 80%;
      display: block;
    }
    &__CardContainer {
      display: none;
    }
  }
  &__TituloServicios {
    text-align: center;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
  }
  &__Representacion {
    margin: 40px 30px 10px;
    &__ContenedorImagenes {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    > p {
      margin-top: 10px;
      font-weight: 400;
      line-height: 21px;
      text-align: justify;
    }
  }
  &__Representacion2 {
    display: none;
  }
  &__SlideOtros {
    margin-top: 20px;
    height: 200px;
  }
  &__SlideOtros2 {
    display: none;
  }
  &__Clientes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 20px;
    padding: 15px;
    height: 200px;
    &__Slide {
      width: 90%;
      max-width: 500px;
    }
    &__Slide2 {
      display: none;
    }
  }
  .NewsContainer {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
  &__News {
    display: none;

    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;
    &__Container {
      margin-top: 10px;
      width: 300px;
      border-radius: 10px;
      border: 1px $gray-light solid;
      &__Img {
        height: auto;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        > img {
          width: 100%;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
        }
      }
      &__Texto {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding: 10px 30px;
        font-size: 24px;
        line-height: 42px;
        font-weight: 500;
        background-color: $gray-dark;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        color: white;
        > p {
          color: white;
          margin-top: 20px;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
        }
        > a {
          font-weight: 500;
          font-size: 20px;
          line-height: 21px;
          margin: 10px 0 10px;
          &:hover {
            cursor: pointer;
            font-weight: 600;
          }
        }
      }
    }
  }
  &__NewsCarousel {
    text-align: center;
    margin-bottom: 30px;
    &__Container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      width: 100px;
      border-radius: 10px;
      border: 1px $gray-light solid;
      &__Img {
        height: auto;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        > img {
          width: 100%;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
        }
      }
      &__Texto {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        margin: 10px 30px;
        font-size: 24px;
        line-height: 42px;
        font-weight: 500;
        > p {
          margin-top: 20px;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
        }
        > a {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          margin: 10px 0 10px;
          &:hover {
            cursor: pointer;
            font-weight: 600;
          }
        }
      }
    }
  }
  &__Youtube {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
  &__Ubicacion {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    gap: 10px;
    > p {
      display: flex;
      align-items: center;
    }
    &__Icon {
      margin-right: 10px;
      color: $blue-dark-medium;
    }
    &__MapContainer {
      position: relative;
      margin-bottom: 30px;
      width: 80%;
      max-width: 800px;
      height: 400px;
    }
  }
  &__Ubicacion2 {
    display: none;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    gap: 10px;
    > div {
      display: flex;
      gap: 50px;
      > p {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
      }
      &__Icon {
        margin-right: 10px;
        color: $blue-dark-medium;
      }
    }
    &__MapContainer {
      position: relative;
      margin-bottom: 30px;
      width: 90%;
      max-width: 900px;
      height: 400px;
    }
  }
}

.Dots {
  position: relative;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 50px;
  > .slick-dots {
    position: absolute;
    bottom: -25px;
    > li button {
      height: 8px;
      border-radius: 4px;
      background: $blue-dark-medium;
    }
    > li.slick-active button {
      height: 8px;
      border-radius: 4px;
      background-color: $blue-primary;
    }
  }
}

.Card2 {
  width: 180px;
  height: auto;
  transition: all 0.5s;
  box-shadow: 15px 15px 30px rgba(25, 25, 25, 0.11),
    -15px -15px 30px rgba(60, 60, 60, 0.082);
  text-align: center;
  overflow: hidden;
}

.Card2 .header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #25344a;
  margin-bottom: 10px;
}

.Card2 .header .img-box {
  width: 100%;
}

.Card2 .header .title {
  font-size: 1.1em;
  letter-spacing: 0.1em;
  font-weight: 500;
  text-transform: uppercase;
  padding: 10px 40px 10px 40px;
  transition: all 0.5s;
  color: #edededc5;
}

.Card2:hover .Card2 .header .title {
  padding: 0;
}

.Card2 .content {
  display: block;
  color: #212121;
  padding: 0 10px;
}

.Card2 .content p {
  transition: all 0.5s;
  font-size: 1em;
}

@media screen and (min-width: 750px) {
  .Home__ServiceContainer {
    height: 440px;
  }
  .Home__ServiceContainer__Slider {
    display: none;
  }
  .Home__ServiceContainer__CardContainer {
    display: flex;
    gap: 15px;
    width: 90%;
  }
  .Home__Representacion {
    display: none;
  }

  .Home__News {
    display: flex;
    width: 500px;
  }

  .Home__NewsCarousel {
    display: none;
  }

  .Home__Representacion2 {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    max-width: 100%;
    &__Izq {
      width: 40vw;
      > img {
        width: 100%;
        max-width: 550px;
        min-width: 380px;
      }
    }
    &__Der {
      width: 40vw;
      > h2 {
        font-size: 20px;
        margin-bottom: 20px;
        margin-top: 30px;
      }
      > article {
        text-align: left;
        margin-bottom: 10px;
        color: $blue-primary;
        font-weight: 600;
        font-size: 20px;
      }
      > p {
        text-align: justify;
      }
    }
    &__ContenedorImagenes {
      display: flex;
      max-width: 80%;
      margin-top: 30px;
    }
  }
  .Home__SlideOtros2 {
    display: block;
    margin-top: 20px;
    height: 200px;
  }
  .Home__SlideOtros {
    display: none;
  }
  .Home__Clientes__Slide {
    display: none;
  }
  .Home__Clientes__Slide2 {
    display: block;
    width: 90%;
    max-width: 400px;
  }
  .Home__Ubicacion {
    display: none;
  }
  .Home__Ubicacion2 {
    display: flex;
  }
}

@media screen and (min-width: 1000px) {
  .Home__ServiceContainer__CardContainer {
    display: flex;
    gap: 15px;
    justify-content: space-around;
    margin: 20px 0 5px 0;
  }
}
