@import "../../styles/colors.scss";

.ClientesContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &__CardsContainer {
    margin: 10px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 80vw;
    &__Cards {
      width: 200px;
      height: 280px;
      border-radius: 10px;
      padding: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      border-radius: 30px;
      box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff;
      > header {
        height: 160px;
        width: 100%;
        display: flex;
        justify-content: space-around;
        gap: 10px;
        flex-direction: column;
        align-items: center;
        > p {
          font-size: 15px;
          color: $blue-primary;
          display: flex;
          gap: 10px;
          justify-content: space-evenly;
          align-items: center;
        }
        > img {
          width: 100%;
        }
      }
      > footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        margin: auto;
        > a {
            text-decoration: none;
            color: $gray-primary;
          > p {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 1.1rem;
            text-align: center;
          }
          &:hover {
            color: $blue-primary;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.Pagination {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 30px;
  margin-bottom: 20px;
}
