@import "../../styles/colors.scss";

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $gray-light;
  .footer-grid {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    justify-content: center;
    align-items: center;
    width: 90%;
    .marcas {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding-top: 17px;
      &__Titulo {
        font-weight: 500;
        color: $blue-primary;
      }
      &__Lista {
        font-weight: 400;
        font-size: 0.8rem;
        margin-left: 30px;
        gap: 0.4rem;
        list-style: none;
        > li {
          > a {
            color: $gray-dark;
            font-size: 1rem;
            &:hover{
              font-weight: 500;
              color: $blue-primary;
              cursor: pointer;
            }
          }
        }
        &__Ubicacion {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          align-items: center;
          border-radius: 8px;
          font-size: 0.5rem;
          gap: 10px;
        }
      }
      &__Contacto {
        display: flex;
        flex-direction: column;
        gap: 10px;
        > li {
          color: $blue-primary;
          list-style: none;
          display: flex;
          align-items: center;
          font-size: 1rem;
          > svg {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .footer-redes {
    width: 100%;
    background: $gray-primary;
    color: $blue-primary;
    height: 130px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    gap: 10px;
    &__icons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      &__div {
        border-radius: 50%;
        background-color: $gray-dark;
        display: flex;
        margin: auto;
        padding: 4px;
        &:hover {
          color: $gray-dark;
          background-color: $blue-primary;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .footer-container {
    .footer-grid {
      flex-wrap: nowrap;
    }
  }
}
