@import "./styles/colors.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  &::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background: $blue-primary;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $blue-dark;
  }
  a {
    color: $blue-primary;
    text-decoration: none;
  }
}

#Light {
  background-color: white;
  color: $blue-dark;
  nav {
    background-color: white;
  }
  h2 {
    color: $blue-primary;
  }
}

#Dark {
  background-color: $gray-primary;
  color: #d9d9d9;
  nav {
    background-color: $gray-primary;
  }
  h2 {
    color: $blue-primary;
  }
}

.sized-container-div {
  margin: auto;
  margin-top: 1%;
  height: 500px;
  width: 100%;
  border: 1px solid #dadada;
}
