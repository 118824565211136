@import "../../styles/colors.scss";

.Slider4Container {
  display: flex !important;
  justify-content: space-evenly;
  align-items: center;
  .Slider4 {
    position: relative;
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-color: $gray-light;
    flex-direction: row;
    width: 100%;
    &__Div {
      display: flex;
      margin: 20px;
      > img {
        max-width: 70%;
        height: 150px;
        margin-right: 15px;
        border-radius: 10px;
        object-fit: cover;
      }
      > div {
        > p {
          color: $gray-dark;
          font-size: 1.5rem;
          font-weight: 600;
        }
      }
    }
  }
  
}
