@import "../../styles/colors.scss";
.Dots {
  > .slick-dots {
    > li button {
      margin-top: -10px;
      height: 8px;
      border-radius: 4px;
      background: $blue-dark-medium;
    }
    > li.slick-active button {
      height: 8px;
      border-radius: 4px;
      background-color: $blue-primary;
    }
  }
}
.Slider3 {
  position: relative;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: $gray-light;
  flex-direction: row;
  width: 100%;
  &__Div {
    display: flex;
    margin: 30px;
    > img {
      max-width: 40%;
      height: 110px;
      margin-right: 15px;
      border-radius: 10px;
      object-fit: cover;
    }
    > div {
      > p {
        color: $gray-dark;
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
  }
}
