@import "../../styles/colors.scss";

.TarjetasContainer {
  height: 300px;
  max-width: 500px;
  border-radius: 8px;
  flex: 0 0 100%;
  scroll-snap-align: start;
  padding: 50px;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &:hover {
    cursor: pointer;
  }
  > img {
    width: 70%;
    border-radius: 15px;
  }
  &__Icono {
    color: $gray-light;
    &:hover {
      color: $blue-primary;
    }
  }
}

.ModalContainer {
  height: 500px;
  background: no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  > img {
    width: 70%;
    border-radius: 15px;
  }
  > p {
    margin-top: 10px;
    color: $gray-light;
    text-align: center;
  }
  &__IconosGreen {
    color: #84bd00;
    margin-top: 15px;
  }
  &__IconosBlue {
    color: $blue-primary;
    margin-top: 15px;
  }
  &__IconosGray {
    color: $blue-dark;
    margin-top: 15px;
  }
  &__IconosRed {
    color: #ff0000;
    margin-top: 15px;
  }
  &__IconosGray {
    color: #8d8d8d;
    margin-top: 15px;
  }
}
