@import "../../styles/colors.scss";

.Slider__Div {
  position: relative;
  display: flex !important;
  justify-content: center;
  align-items: center;
  .Info {
    height: 100px;
    width: 90%;
    border: none;
    background: rgba(33, 150, 243, 0.8);
    border-radius: 5px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    color: white;
    text-align: center;
    &__marca {
      font-size: 1.5rem;
    }
    &__descrip {
      font-size: 0.8rem;
    }
  }

  .Info2 {
    height: 90px;
    width: 270px;
    border: none;
    background: rgba(33, 150, 243, 0.8);
    border-radius: 5px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 10px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    color: white;
    text-align: center;
    &__descrip2 {
      font-size: 1.4rem;
    }
  }
  &__Butt {
    position: absolute;
    top: 300px;
    background-color: white;
    border-radius: 5px;
    font-weight: 800;
    text-transform: uppercase;
    display: block;
    Button {
      font-weight: 700;
    }
  }
}

@media screen and (min-width: 650px) {
  .Slider__Div {
    background-color: $gray-primary;
    &__Butt {
      top: 400px;
    }
    > img {
      height: 500px !important;
      max-width: 1100px;
    }
    .Info {
      max-width: 500px;
    }
  }
}
