@import "../../styles/colors.scss";

.NewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__ImgTitle {
    width: 100%;
  }
  &__Text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 85vw;
    > p {
      color: $gray-dark;
      text-align: justify;
    }
    > h4 {
      color: $blue-primary;
      font-size: 20px;
    }
    &__ImgContainer {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      > img {
        width: 100vw;
        max-width: 60vw;
      }
    }
  }
  &__ImgContainer {
    width: 40vw;
    background-color: $gray-dark;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
      width: 20vw;
      min-width: 100px;
    }
  }
}

.shadow__btn {
  padding: 10px 20px;
  border: none;
  font-size: 17px;
  color: #fff;
  border-radius: 7px;
  letter-spacing: 4px;
  font-weight: 700;
  text-transform: uppercase;
  transition: 0.5s;
  transition-property: box-shadow;
  > a {
    color: #fff;
    &:hover {
      cursor: pointer;
    }
  }
}

.shadow__btn {
  background: rgb(0, 140, 255);
}

.shadow__btn:hover {
  box-shadow: 0 0 30px rgb(0, 140, 255);
  cursor: pointer;
}

@media screen and (min-width: 700px) {
  .NewContainer {
    &__Text {
      width: 90vw;
    }
  }
}
