@import "../../styles/colors.scss";

.slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  .slider-image {
    display: flex;
    justify-content: center;
    background-color: transparent;
    > img {
      border-radius: 10px;
      width: 80%;
      padding: 10px;
      height: auto;
      display: block;
    }
  }

  .slider-prev-btn,
  .slider-next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent;
    color: $blue-primary;
    &:hover {
      color: $blue-dark;
    }
  }

  .slider-prev-btn {
    left: 10px;
  }

  .slider-next-btn {
    right: 10px;
  }
}
