.SearchBarMobile {
  display: block;
}

.SearchBarDesktop {
  display: none;
}

.VideoSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Titulo {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 15px;
}

.CatalogosSection {
  padding-bottom: 20px;
}

@media screen and (min-width: 700px) {
  .SearchBarMobile {
    display: none;
  }

  .SearchBarDesktop {
    display: block;
  }
}
