@import "../../styles/colors.scss";

.ButtonV {
  width: 100%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  &__Container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    height: 300px;
    &__Play {
      position: absolute;
      color: white;
      top: 40px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 15px;
      align-items: center;
      &__Text {
        height: auto;
        width: 90%;
        border-radius: 10px;
        background: rgba(50, 63, 82, 0.8);
        backdrop-filter: blur(1px);
        color: white;
        padding: 5px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        > article {
          font-size: 14px;
        }
      }
    }
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 15px;
    }
  }
}

.ContainerVideo {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  &__ReactPlayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}
