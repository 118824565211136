@import "../../styles/colors.scss";
ul {
  li {
    > a {
      text-decoration: none;
      &:visited {
        text-decoration: none;
        color: inherit;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}
