@import "../../styles/colors.scss";

.footer-container-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  .footer-redes {
    width: 100%;
    background: $gray-primary;
    color: $blue-primary;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    margin-top: 30px;
    gap: 10px;
    padding-bottom: 180px;
    &__icons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      &__div {
        border-radius: 50%;
        background-color: $gray-dark;
        display: flex;
        margin: auto;
        padding: 4px;
        &:hover {
          color: $gray-dark;
          background-color: $blue-primary;
          cursor: pointer;
        }
      }
    }
  }
}
