@import "../../styles/colors.scss";

.Modal {
  border-radius: 10px;
  .Modal-Text {
    display: flex;
    flex-direction: column;
    .Modal-Img {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      flex-wrap: wrap;
      > a {
        transition: transform 0.3s ease-in-out;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        color: $gray-dark;
        &:hover {
          cursor: pointer;
          transform: scale(1.1);
          font-weight: 600;
          color: $blue-primary;
        }
        > img {
          width: 190px;
          display: flex;
          flex-direction: column;
          aspect-ratio: 3/2;
          object-fit: contain;
          transform: translateY(1px);
        }
      }
    }
    .Modal-I {
      border: 1px solid $blue-primary;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      flex-wrap: wrap;
      border-radius: 10px;

      > div {
        > img {
          width: 100%;
          border-radius: 10px;
        }
      }
    }
  }
}
