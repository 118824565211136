.Mobil {
  display: block;
}

.Escritorio {
  display: none;
}

@media screen and (min-width: 800px) {
  .Mobil {
    display: none;
  }

  .Escritorio {
    display: block;
  }
}
