@import "../../styles/colors.scss";

.SlidervContainer {
  display: flex;
  margin: 0 10px;
  max-width: 900px;
  height: auto;
  gap: 4px;
  &__Izq {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 30%;
    max-width: 400px;
    gap: 4px;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 400px;
      border: 1px solid rgba(236, 236, 236, 0.8);
      border-radius: 4px;
      > img {
        width: 100%;
        max-width: 400px;
        &:hover {
          cursor: pointer;
          filter: opacity(0.9);
        }
      }
    }
  }
  &__Der {
    border: 1px solid rgba(236, 236, 236, 0.8);
    border-radius: 4px;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 800px;
      > img {
        max-width: 800px;
        width: 100%;
        &:hover {
          cursor: pointer;
          filter: opacity(0.9);
        }
      }
    }
  }
  &__Modal {
    width: 100%;
    > img {
      width: 100%;
    }
  }
}

@media screen and (min-width: 700px) {
  .SlidervContainer {
    justify-content: flex-end;
    &__Izq {
      width: 128px;
    }
  }
}
