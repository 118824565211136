
.HeaderPages {
    height: 170px;
    width: 100%;
    border-radius: 0px 0px 40px 40px;
    display: flex;
    justify-content: center;
    padding: 40px;
    &__DivImg {
      border-radius: 15px;
      width: 100%;
      backdrop-filter: blur(25px);
      display: flex;
      justify-content: center;
      padding: 18px 30px;
      background: rgba(255, 255, 255, 0.9);
      min-width: 300px;
      max-width: 400px;
      > img {
        width: 100%;
      }
    }
  }