
.ImagenMarca {
    display: flex;
    align-items: center;
    height: 90px;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    width: 40%;
    max-width: 200px;
    margin: 8px;
    height: auto;
    transition: transform 0.3s ease-in-out;
    &:hover{
      cursor: pointer;
      transform: scale(1.1);
    }
    > img {
      width: 100%;
      padding: 5px;
      transform: translateY(1px);
    }
  }