.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: #25d366;
    border-radius: 50%;
    z-index: 999;
    transition: transform 0.3s ease-in-out;
  
    &:hover {
      transform: scale(1.1);
      cursor: pointer;
    }
  
    i {
      color: #fff;
      font-size: 32px;
      transform: translateY(2px);
    }
  }
  