@import "../../styles/colors.scss";

.Container {
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
  .menuTop {
    margin: 15px 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    &__Correo {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
    &__Telefono {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
    &__Ubicacion {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
    &__Redes {
      display: flex;
      gap: 15px;
      font-size: 30px;
    }
  }
  .menu {
    color: #fff;
    font-size: 17px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 0;
    font-weight: 600;
    background-color: $gray-dark;
    .menu__list {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      width: 100vw;
      .menu__item {
        margin: 0 15px;
        &--has-submenu {
          position: relative;
          .submenu {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 1;
            display: none;
            background-color: #001529;
            li {
              padding: 10px;
              a {
                color: #fff;
                &:hover {
                  color: #40a9ff;
                  cursor: pointer;
                }
              }
            }
          }
          &:hover .submenu {
            display: block;
            cursor: pointer;
          }
        }
        a {
          color: #fff;
          text-decoration: none;
          &:hover {
            color: #40a9ff !important;
            cursor: pointer;
          }
        }
      }
    }
  }
}
