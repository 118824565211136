@import "../../styles/colors.scss";

.BrandPage {
  &__Header {
    height: 180px;
    width: 100%;
    border-radius: 0px 0px 40px 40px;
    display: flex;
    justify-content: center;
    padding: 40px;
    &__DivImg {
      border-radius: 15px;
      width: 100%;
      backdrop-filter: blur(25px);
      display: flex;
      justify-content: center;
      padding: 18px 30px;
      background: rgba(255, 255, 255, 0.9);
      min-width: 300px;
      max-width: 400px;
      > img {
        width: 75%;
        // height: auto;
      }
    }
  }
  &__Bread {
    margin-top: 20px;
    margin-bottom: 10px;
    padding-left: 20px;
  }
  > h1 {
    padding-left: 25px;
  }
  &__QueEs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > h3 {
      margin-top: 15px;
      margin-bottom: 10px;
      padding-left: 20px;
      color: $blue-primary;
      font-weight: 500;
    }
    &__Article {
      padding-left: 20px;
      padding-right: 20px;
      text-align: justify;
      font-weight: 400;
      margin-bottom: 20px;
      max-width: 900px;
    }
    &__Cotizar {
      padding-left: 20px;
      margin-bottom: 20px;
      > a {
        font-size: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        text-decoration: none;
      }
      &:visited {
        color: inherit;
      }
    }
    &__Slider {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 5px 30px;
      > img {
        width: 100%;
        border-radius: 15px;
        max-width: 500px;
      }
    }
  }
  &__Buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px 50px;
    background-color: $gray-light;
    width: 100%;
    align-items: center;
    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      padding: 10px;
      border-radius: 50px;
      background-color: $blue-buttons;
      color: white;
      font-weight: 500;
      font-size: 15px;
      max-width: 300px;
      width: 100%;
      &:hover {
        background-color: $gray-primary;
      }
    }
  }
  &__Video {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    background-color: none;
    > h3 {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__Products {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
    &__Article {
      padding-left: 20px;
      padding-right: 20px;
      text-align: justify;
      font-weight: 400;
      margin-bottom: 20px;
      > p {
        margin-top: 10px;
      }
    }
    &__Tarjetas {
      display: flex;
      gap: 10px;
      scroll-snap-type: x mandatory;
      scroll-padding: 24px;
      overflow-x: scroll;
      width: 90%;
      padding: 24px;
    }
  }
  &__Image {
    margin-top: 20px;
    max-width: 900px;
    &__Marco {
      background-color: $blue-primary;
      color: white;
      padding: 10px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &__Slide {
      &__Img {
        height: 300px;
        width: 150%;
      }
      &__Slider {
        height: 98%;
        width: 4px;
        background: $blue-primary;
      }
    }
  }
  &__Catalogos {
    text-align: center;
    margin-top: 20px;
    &__SlidePDF {
      margin-top: 20px;
      height: auto;
      text-align: center;
    }
  }
  .Titulo {
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 700px) {
}
