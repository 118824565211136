@import "../../styles/colors.scss";

.Container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
  .Contacto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__Titulo {
      font-size: 22px;
      text-align: center;
      margin: 5px;
    }
    &__Text {
      font-size: 15px;
    }
  }
  &__Form {
    display: flex;
    padding: 30px 15px;
    justify-content: center;
    max-width: 500px;
    &__Item {
      display: flex;
      gap: 2px;
      justify-content: center;
      align-items: center;
      max-width: 600px;
      width: 100%;
      &__Select {
        display: flex;
        width: 100%;
        &__Option {
          padding-top: 2px;
        }
      }
    }
  }
  &__Ubicacion {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    width: 400px;
    gap: 10px;
    > p {
      display: flex;
      align-items: center;
    }
    &__Icon {
      margin-right: 10px;
      color: $blue-dark-medium;
    }
    &__MapContainer {
      position: relative;
      width: 100%;
      height: 400px;
    }
  }
}

.Contacto2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
  &__Img {
    margin: 0 40px;
    > a {
      > img {
        width: 100%;
        max-width: 250px;
        margin-top: 20px;
        margin-bottom: 20px;
        filter: opacity(0.6);
        &:hover {
          cursor: pointer;
          filter: opacity(0.9);
        }
      }
    }
  }
}
