.card {
  width: 400px;
  height: 250px;
  transition: all 0.5s;
  box-shadow: 15px 15px 30px rgba(25, 25, 25, 0.11),
    -15px -15px 30px rgba(60, 60, 60, 0.082);
  text-align: center;
  overflow: hidden;
  border-radius: 10px;
}

.card:hover {
  height: 350px;
}

.card .header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #25344a;
  margin-bottom: 16px;
}

.card .header .img-box {
  width: 100%;
}

.card .header .title {
  font-size: 1.1em;
  letter-spacing: 0.1em;
  font-weight: 500;
  text-transform: uppercase;
  padding: 10px 40px 10px 40px;
  transition: all 0.5s;
  color: #edededc5;
}

.card:hover .card .header .title {
  padding: 0;
}

.card .content {
  display: block;
  color: #212121;
  margin: 0 18px;
}

.card .content p {
  transition: all 0.5s;
  font-size: 1em;
  margin-bottom: 8px;
}

@media screen and (min-width: 700px) {
  .card {
    height: 280px;
  }
  .card:hover {
    height: 390px;
  }
}

@media screen and (min-width: 1000px) {
  .card {
    height: 245px;
  }
  .card:hover {
    height: 350px;
  }
}
