@import "../../styles/colors.scss";

.Dots {
  > .slick-dots {
    > li button {
      height: 8px;
      border-radius: 4px;
      background: $blue-dark-medium;
    }
    > li.slick-active button {
      height: 8px;
      border-radius: 4px;
      background-color: $blue-primary;
    }
  }
}

.SlidePDF {
  background-color: $gray-light;
  padding: 10px;
  height: auto;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  &:hover {
    cursor: pointer;
  }
  &__Container {
    height: 250px;
    width: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    max-width: 150px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
      rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    border-radius: 8px;
    > img {
      height: 160px;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      width: 100%;
      padding: 10px;
    }
    > p {
      padding: 5px;
      color: $blue-primary;
      font-weight: 600;
    }
    &__Ver {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $blue-primary;
      padding-bottom: 10px;
      &:hover {
        color: $blue-dark;
      }
    }
  }
}
