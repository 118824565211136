@import "../../styles/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  transition: all 0.3s;
  .header-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 80px;
    button {
      display: flex;
      flex-direction: column;
      width: 4rem;
      height: 2rem;
      border: 0;
      background: transparent;
      gap: 0.4rem;
      padding-right: 10px;
      position: relative;
      .Icon1 {
        transition: all 0.3s;
        color: $blue-primary;
        font-size: 40px;
        position: absolute;
        top: 0;
        right: 10px;
      }
      .Icon2 {
        transition: all 0.3s;
        color: $blue-primary;
        font-size: 40px;
        position: absolute;
        top: 0;
        right: 10px;
      }
    }
  }
  .MenuMobile {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-evenly;
    background-color: white;
    &__DarkMode {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 20px;
      color: $blue-primary;
      &:hover {
        cursor: pointer;
      }
    }
    &__MenuPrincipal {
      display: flex;
      align-items: center;
      flex-direction: column;
      border-radius: 10px;
      margin: 15px;
      &__Container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 10px;
        a {
          width: 90%;
          border-radius: 20px;
          border: 2px solid $gray-light;
          text-decoration: none;
          background-color: transparent;
          padding: 5px;
          text-align: center;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          :visited {
            color: inherit;
          }
          &:hover {
            font-weight: 600;
            cursor: pointer;
            color: $blue-primary;
          }
        }
        &__Collapse {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          gap: 8px;
          a {
            width: 45%;
            border-radius: 20px;
            text-decoration: none;
            border: 1px $gray-light solid;
            background-color: transparent;
            padding: 5px;
            text-align: center;
            font-weight: 500;
            :visited {
              color: inherit;
            }
            &:hover {
              font-weight: 600;
              color: $blue-primary;
              cursor: pointer;
            }
          }
        }
      }
    }
    &__DatosContacto {
      display: flex;
      flex-direction: column;
      margin-left: 50px;
      margin-bottom: 20px;
      > p {
        display: flex;
        color: $blue-primary;
        align-items: center;
        margin-top: 10px;
      }
    }
  }
}
