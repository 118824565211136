.ContactPage {
  &__Header {
    height: 130px;
    width: 100%;
    border-radius: 0px 0px 40px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    > h2 {
      color: white !important;
      font-size: 30px;
    }
  }
  &__Bread {
    margin-top: 20px;
    margin-bottom: 10px;
    padding-left: 20px;
  }
}
