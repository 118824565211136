@import "../../styles/colors.scss";

.slider-container2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  position: relative;
  gap: 10px;
  .slider2-image {
    display: flex;
    justify-content: center;
    background-color: transparent;
    gap: 10px;
    > img {
      border-radius: 10px;
      width: 50%;
      padding: 10px;
      display: block;
      aspect-ratio: 3/2;
      object-fit: contain;
      mix-blend-mode: color-burn;
    }
  }

  .slider2-prev-btn,
  .slider2-next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent;
    color: $blue-primary;
    &:hover {
      color: $blue-dark;
    }
  }

  .slider2-prev-btn {
    right: 10px;
  }

  .slider2-next-btn {
    left: 10px;
  }
}
